:root {
  --purple: #6f34de;
  --dark-purple: #5d2cb9;
  --darkest-purple: #702898;
  --light-gray: #f4f7fe;
  --light-green: #adffe7;
}

/* GLOBALS */
html,
body {
  background-color: var(--light-gray);
}

/*NAVBAR*/
.navbar-brand img {
  max-height: 50px;
  width: auto;

}


@media (max-width: 768px) {
  .navbar-brand {
    box-shadow: none;
  }
}

@media (min-width: 768px) {
  .navbar-brand {
    border: 1px solid #ddd;
    border-bottom: 0;
    text-align: center;
    padding-top: 50px;
    display: none;
  }
}

/* SIDEBAR */

@media (min-width: 768px) {


  #sidebar.sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.sidebar .nav-item {
  margin-bottom: 5px;
}

.sidebar .nav-link {
  color: #444;
}

.sidebar .nav-link.active {
  color: var(--purple);
}

/* BUTTONS */
.btn--purple {
  background-color: var(--purple);
  color: white;
  border-color: var(--purple) !important;
}

.btn--purple.btn--active,
.btn--purple:hover {
  background-color: var(--dark-purple);
  color: white !important;
}

.btn-outline-purple {
  color: var(--purple);
  border: 1px solid var(--purple);
}

.btn-outline-purple:hover {
  background-color: var(--dark-purple);
  color: white;
}

/* CONTAINERS */
.app__container {
  box-shadow: 0px 0px 16px 0px rgba(169, 169, 169, 0.75);
  width: 100%;
  border-radius: 6px;
  margin: 0 auto;
  background-color: white;
}

@media (min-width: 768px) {
  .app__container {
    /* width: 44%; */
  }
  .w-md-50 {
    width: 50%;
  }
}

.app__container--transparent {
  background-color: transparent;
  box-shadow: none;
}

.list-group-horizontal>.list-group-item {
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0 !important;
  color: #a0a0a0;
}

.border-bottom-purple {
  border-bottom: 1px solid var(--dark-purple) !important;
  border-radius: 0 !important;
}


/* UTILS */
.text-purple {
  color: var(--darkest-purple) !important;
}

.bg-light-green {
  background-color: var(--light-green);
}

.bg-gray {
  background-color: var(--light-gray) !important;
}

/* datepicker */
.react-datepicker-popper {
  z-index: 2 !important;
}